import { Field } from "formik"
import React from "react"

const StyledCheckBox = ({ name, label, value }) => {
  return (
    <label>
      <Field
        className="h-6 w-6 border rounded-[5px] border-black align-top bg-no-repeat bg-center bg-contain mr-2 -mt-[2px] cursor-pointer checkbox-field"
        type="checkbox"
        name={name}
        value={value}
      />
      {label}
    </label>
  )
}

export default StyledCheckBox
