const formInitialState = {
  businessName: "",
  role: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  socialMediaHandles: "",
  howToHelp: "",
  interestAreas: [],
  howDidYouHear: "default",
}

export default formInitialState
