import { ErrorMessage } from "formik"
import React from "react"

const FormErrorMessage = ({ name }) => {
  return (
    <ErrorMessage
      className="text-xxs text-red-650 exclamation flex items-end gap-1 relative top-[25px] z-20 h-0"
      name={name}
      component="div"
    />
  )
}

export default FormErrorMessage
