import * as Yup from "yup"

const validationSchema = Yup.object({
  businessName: Yup.string().required("This field is required"),
  role: Yup.string().required("This field is required"),
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),
  email: Yup.string().email().required("This field is required"),
  phone: Yup.number().required("This field is required"),
  interestAreas: Yup.array()
    .min(1, "At least one element must be selected")
    .of(Yup.string().required())
    .required(),
})

export default validationSchema
