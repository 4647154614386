import { Field } from "formik"
import React from "react"

const FormDropDown = () => {
  const dropDownOptions = [
    "News Mention/Article",
    "Social Media",
    "Friends/Family",
    "Other",
  ]

  return (
    <div className="flex flex-col w-full gap-2">
      <label htmlFor="howDidYouHear">
        How did you hear about Blackness in Full Bloom?
      </label>
      <div className="w-full relative" id="default-select">
        <Field
          className="bg-transparent appearance-none  w-full text-base laptop-standard:text-lg border py-4 pl-10 rounded-full laptop-standard:py-6 laptop-standard:pl-13"
          as="select"
          name="howDidYouHear"
        >
          <option value="default" disabled>
            Select an option below
          </option>

          {dropDownOptions.map((o, i) => (
            <option key={i} value={o.toLowerCase().replace(/(\s|\/)/g, "_")}>
              {o}
            </option>
          ))}
        </Field>
      </div>
    </div>
  )
}

export default FormDropDown
