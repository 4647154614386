import React from "react"
import { Field, useField } from "formik"
import FormErrorMessage from "../../atoms/FormErrorMessage"

const FormInput = ({ name, type, label, fieldClasses }) => {
  return (
    <div className={`flex flex-col ${fieldClasses ? fieldClasses : ""}`}>
      <label htmlFor={name}>{label}</label>
      <Field type={type} name={name} as={MyTextInput} />
      <FormErrorMessage name={name} />
    </div>
  )
}

export default FormInput

const MyTextInput = ({ ...props }) => {
  const [field] = useField(props)
  return (
    <input
      className="mt-5 bg-transparent border-b-[1px] pb-1 autofill:bg-transparent focus:outline-none"
      {...field}
      {...props}
    />
  )
}
