import { navigate } from "gatsby"
import { getSubmissionToken } from "./getSubmissionToken"

export const showToConsole = (values, callBack) => {
  setTimeout(() => {
    alert(JSON.stringify(values, null, 2))
    callBack(false)
  }, 400)
}

export const submitApplication = async (values, callBack) => {
  const token = await getSubmissionToken()
  try {
    const response = await fetch(process.env.GATSBY_BIFB_LAMBDA, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })

    if (response.ok) {
      navigate("/application-success")
    } else {
      navigate("/application-error")
    }
  } catch (error) {
    navigate("/application-error")
  } finally {
    callBack(false)
  }
}
