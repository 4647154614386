import React from "react"
import FormInput from "./FormInput"

const FormInputContainer = () => {
  const formFieldsList = [
    { name: "businessName", type: "text", label: "Business Name*" },
    { name: "role", type: "text", label: "Your Role*" },
    { name: "firstName", type: "text", label: "First Name*" },
    { name: "lastName", type: "text", label: "Last Name*" },
    { name: "email", type: "text", label: "Email Address*" },
    { name: "phone", type: "number", label: "Phone Number*" },
  ]

  return (
    <>
      <div className="grid grid-cols-1 gap-10 w-full laptop-standard:grid-cols-2 laptop-standard:gap-x-5 laptop-standard:gap-y-13">
        {formFieldsList.map((f, i) => (
          <FormInput name={f.name} type={f.type} label={f.label} key={f.name} />
        ))}
      </div>
      <FormInput
        name="socialMediaHandles"
        type="text"
        label="Please list your social media handles:"
        fieldClasses="w-full"
      />
    </>
  )
}

export default FormInputContainer
