import React from "react"
import FormBodyContainer from "../organism/FormBodyContainer"
import FormContainer from "../organism/FormContainer"

const ApplicationFormBody = () => {
  return (
    <FormBodyContainer>
      <FormContainer />
    </FormBodyContainer>
  )
}

export default ApplicationFormBody
