import React from "react"
import { Form, Formik } from "formik"
import formInitialState from "../../helpers/formInitialState"
import validationSchema from "../../helpers/formValidators"
import FormInputContainer from "../molecules/form/FormInputContainer"
import FormTextarea from "../molecules/form/FormTextarea"
import CTAButton from "../atoms/CtaButton"
import LegalDisclosure from "../molecules/form/FormLegalDisclosure"
import FormCheckBoxGroup from "../molecules/form/FormCheckBoxGroup"
import FormDropDown from "../molecules/form/FormDropDown"
import { submitApplication } from "../../services/submitForm"
import FormMainTitle from "../atoms/textStyledComponents/FormMainTitle"

const FormContainer = () => {
  return (
    <div className="relative z-10 min-h-screen text-base laptop-standard:text-md">
      <FormMainTitle>
        Ready to apply? <br /> Let's do this.
      </FormMainTitle>
      <p className="mt-13">*Required Field</p>
      <Formik
        initialValues={formInitialState}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) =>
          submitApplication(values, setSubmitting)
        }
      >
        {({ isSubmitting }) => (
          <Form className="flex flex-col gap-13 items-start pt-13 laptop-standard:w-4/6">
            <FormInputContainer />
            <FormTextarea />
            <FormCheckBoxGroup />
            <FormDropDown />
            <LegalDisclosure />
            <CTAButton caption="Submit" type="submit" disabled={isSubmitting} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default FormContainer
