import React from "react"

const LegalDisclosure = () => {
  return (
    <p className="text-xs leading-[15px] font-light tracking-normal">
      By submitting this form, you agree that if selected to participate in the
      Program DeutschLA may use the name of the business and the business owner
      in materials to promote the Program. By providing this information you
      agree DeutschLA may contact you regarding your interest in the Blackness
      in Full Bloom program and you authorize DeutschLA to: (i) process the
      information provided for lawful, business related purposes; and (ii) store
      and transmit your information, directly or through our service providers,
      whether within your country of residence, the United States, or elsewhere.
      <br />
      To learn more, please read our{" "}
      <a href="/privacy" target="_blank" rel="noreferrer" className="underline">
        Privacy Notice.
      </a>
    </p>
  )
}

export default LegalDisclosure
