import React from "react"
import FormErrorMessage from "../../atoms/FormErrorMessage"
import StyledCheckBox from "../../atoms/StyledCheckBox"

const FormCheckBoxGroup = () => {
  const checkboxValues = [
    "Branding",
    "Social Media",
    "Design",
    "Media Buying",
    "Production",
    "PR",
  ]

  return (
    <div
      role="group"
      aria-labelledby="checkbox-group"
      className="flex flex-col gap-6 items-start"
    >
      <div className="mb-5">
        <p className="laptop-standard:text-md">
          What specific areas are you most interested in? (Select all that
          apply.)*
        </p>
        <FormErrorMessage name="interestAreas" />
      </div>
      {checkboxValues.map((value, i) => (
        <StyledCheckBox
          name="interestAreas"
          value={value.toLowerCase().split(" ").join("_")}
          label={value}
          key={i}
        />
      ))}
    </div>
  )
}

export default FormCheckBoxGroup
