import React from "react"
import ApplicationFormBody from "../components/templates/ApplicationFormBody"
import GlobalTemplate from "../components/templates/GlobalTemplate"
import SeoComponent from "../components/atoms/SeoComponent"

const apply = () => {
  return (
    <>
      <GlobalTemplate stickyNavbar>
        <ApplicationFormBody />
      </GlobalTemplate>
    </>
  )
}

export default apply

export const Head = () => (
  <SeoComponent pageTitle="Blackness in Full Bloom | Apply Now" />
)
