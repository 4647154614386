import React from "react"
import { Field, useField } from "formik"

const FormTextarea = () => {
  return (
    <div className="w-full">
      <label htmlFor="howToHelp">
        Tell us how Blackness in Full Bloom could help your business:
      </label>
      <Field name="howToHelp" as={CustomTextArea} />
    </div>
  )
}

export default FormTextarea

const CustomTextArea = ({ ...props }) => {
  const [field] = useField(props)
  return (
    <textarea
      className="bg-transparent p-2 border rounded-md mt-5 w-full min-h-[300px] laptop-standard:min-h-40 focus:outline-none"
      {...props}
      {...field}
      cols="30"
      rows="5"
    ></textarea>
  )
}
